
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import Banner from "./components/Banner.vue";
import TimesAndAddr from "./components/TimesAndAddr.vue";
import showDetail from "./components/showDetail.vue";
import Notice from "@/components/Notice.vue";
import YbyScore from "@/components/YbyScore.vue";
import Comment from "./components/Comment.vue";
import Bottom from "./components/Bottom.vue";
import ProjectPopup from "./components/projectPopup.vue";
import SelectTicket from "@/views/ProjectDetail/components/SelectTicket.vue";
import recommendYuboya from "@/components/Yuboya/recommendYuboya.vue";
import {
  Button,
  Row,
  Tab,
  Tabs,
  Popup,
  Toast,
  Dialog,
  Image,
  NoticeBar,
  Col,
} from "vant";
import CouponLabel from "./components/CouponLabel.vue";
import salePolicy from "./components/salePolicy.vue";
import TourStation from "./components/TourStation.vue";
import AdvertsBanner from "./components/AdvertsBanner.vue";
import FlashActivity from "./components/FlashActivity.vue";
import { monitorEvent } from "@/utils/youMengMonitor";
import { ResponseData } from "@/store/modules/interfaceCache";
import { wxShare } from "@/utils/wechat";
import MyNavbar from "@/components/MyNavbar.vue";

@Component({
  components: {
    Mixin,
    TimesAndAddr,
    Banner,
    showDetail,
    Notice,
    YbyScore,
    Comment,
    Bottom,
    ProjectPopup,
    SelectTicket,
    CouponLabel,
    salePolicy,
    TourStation,
    AdvertsBanner,
    FlashActivity,
    recommendYuboya,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
    [NoticeBar.name]: NoticeBar,
    [Col.name]: Col,
    MyNavbar,
  },
})
export default class detailComp extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public activityId!: string;
  @Prop({ default: -1, type: Number }) public productId!: number;
  productReservation = false;
  public tourId = "";
  projectDetailChange(projectDetail: good.ProjectDto): void {
    const productName = projectDetail.productName || "保利票务";
    window.document.title = productName;

    if (projectDetail.tourId) {
      this.tourId = projectDetail.tourId;
      const tourStation = this.$refs["tour-station"] as TourStation;
      if (tourStation) {
        tourStation.getToursList();
      }
    }
    this.getComment();
    this.productSource = projectDetail.productSource!;

    wxShare(
      productName,
      "爱演出 爱生活-保利票务",
      projectDetail.img!,
      location.origin + `/#/projectdetail/${this.productId}/null?theaterId=40`
    );
  }

  public fromPath = "";
  public isCollect = false; // 收藏标识
  public noticeShowList: Array<good.ProjectNoticeDto> = [];
  public projectDetail: good.ProjectDto = {};
  public showInfo: good.ProjectShowsDto = {};
  public showInfoDetailList: Array<good.ShowInfoDetailDto> = [];
  public flashShowInfo: good.FlashSaleDto = {}; // 限时抢购场次信息
  public flashShowInfoDetailList: Array<good.ShowInfoDetailDto> = []; // 限时抢购场次集合
  public eventType = "";
  public showTicket = false;
  public siteName = "";
  public activityList: Array<good.FlashSaleDto> = [];
  public showTimes = "";
  public showScrollTop = "-1";
  public val = "";
  public couponList: good.SimpleCouponDto[] = [];
  public advertList: cms.TheaterAdvertDto[] = [];
  public tourList: good.TourProductDto[] = [];
  productSource = ""; //项目来源
  showDownload = false;
  activityState = "";
  isPreferential = false; // 是否有优惠政策
  public commentShowList: Array<member.ProductCommentDTO> = [];
  public commentTotal = 0;

  /**
   * 刷新页面
   */
  refresh(): void {
    this.amapLocate(); // 定位
    this.updateData();
  }
  created() {
    if (this.AppSource == "harmonyOS") {
      if (this.$route.query.longitude) {
        this.LocationModule.SET_longitude_PERSIST(
          Number(this.$route.query.longitude)
        );
      }
      if (this.$route.query.latitude) {
        this.LocationModule.SET_latitude_PERSIST(
          Number(this.$route.query.latitude)
        );
      }
    }
  }
  mounted(): void {
    window.scrollTo(0, 0);
  }
  refreshFlash(index: number) {
    this.activityList[index].activityState = "0";
  }
  getComment(): void {
    if (this.productId) {
      this.$api.memberApi.comment.praiseCommentList(
        this.projectDetail.tourId ? "02" : "01",
        this.projectDetail.tourId
          ? this.projectDetail.tourId
          : String(this.productId),
        { pageNum: 1, pageSize: 3 },
        (data) => {
          this.commentShowList = data.data.records!;
          this.commentTotal = data.data.total!;
        }
      );
    }
  }
  /**
   * 开票倒计时到 0 时弹出事件后更新数据
   * 为了兼容后端数据问题导致递归，这里单独提出来区别于 updateData() 方法
   */
  saleStart(): void {
    this.$api.goodApi.show.getShowInfos(
      this.productId,
      {
        distributionChannelId: this.distributionChannelId,
        distributionSeriesId: this.distributionSeriesId,
      },
      ({ data }) => {
        if (data.productStatus == "12") {
          // 下边这些都是数据异常情况，为了避免递归，这些异常情况不更新数据
          let showInfoDetailList = data.showInfoDetailList;
          if (!showInfoDetailList || showInfoDetailList.length < 1) {
            console.error(
              "!showInfoDetailList || showInfoDetailList.length < 1"
            );
            return;
          }
          let firstShow = showInfoDetailList[0];
          let saleBeginTime = firstShow.saleBeginTime;
          if (!saleBeginTime) {
            console.error("!saleBeginTime");
            return;
          }
          let gapMillisecond = parseInt(saleBeginTime) - new Date().valueOf();
          if (gapMillisecond <= 0) {
            console.error('productStatus == "12" && gapMillisecond <= 0');
            return;
          }
        }
        this.showInfo = data;
        if (!this.showInfo.showInfoDetailList) {
          this.showInfo.showInfoDetailList = [];
        } else {
          this.showInfoDetailList = this.showInfo.showInfoDetailList;
          if (this.showInfoDetailList.length === 1) {
            this.showTimes = this.showInfoDetailList[0].saleBeginTimeStr!;
          }
          if (this.showInfoDetailList[0]) {
            this.noticeShowList = this.showInfoDetailList[0].showNotices!;
          }
        }
        this.updateOther();
      }
    );
  }
  downloadApp(): void {
    // 下载app
    this.showDownload = true;
    this.$router.push("/mine/download-app");
  }
  closeDownLoad(): void {
    localStorage.setItem("downloadAppData", this.getData());
    this.showDownload = false;
  }

  /**
   * 异步更新数据
   */
  updateData(): void {
    // 判断缓存是否有提示安装app日期 && 分销渠道的项目不展示app下载弹窗
    let currentData = localStorage.getItem("downloadAppData");
    if (
      currentData !== this.getData() &&
      !this.$route.query.distributionChannelId &&
      this.$AppSource &&
      this.AppSource !== "harmonyOS"
    ) {
      this.showDownload = true;
    } else {
      this.showDownload = false;
    }
    if (this.activityId) {
      this.getActivityInfo();
    }
    this.getShows();
    this.updateOther();
  }

  updateOther(): void {
    Toast.loading({
      duration: 0,
      forbidClick: true,
    });
    const productId = this.productId;
    this.InterfaceCacheModule.getData({
      cacheKey: "product.getProjectInfo",
      apiMethod: this.$api.goodApi.product.getProjectInfo,
      params: [
        productId,
        {
          distributionChannelId: this.distributionChannelId,
          distributionSeriesId: this.distributionSeriesId,
        },
      ],
      expireInterval: 28800, //缓存时间 480 分钟
      randInterval: 300, //5分钟随机时间
      callback: ({ data }) => {
        this.projectDetail = data;
        if (data.preferentialDto) {
          for (let key in data.preferentialDto) {
            if (data.preferentialDto[key].length) {
              this.isPreferential = true;
            }
          }
        }
        this.ProjectDetailCacheModule.SET_projectDetail(data);
        this.projectDetailChange(data);
        Toast.clear();
        if (data.isSellOut === "4") {
          this.$api.goodApi.show.productCollectRecord(
            this.productId,
            ({ data }) => {
              this.productReservation = data;
            }
          );
        } else {
          if (data.tourId) {
            this.InterfaceCacheModule.getData({
              cacheKey: "tour.getProjectById",
              apiMethod: this.$api.goodApi.tour.getProjectById,
              params: [data.tourId],
              expireInterval: 28800, //缓存时间 480 分钟
              randInterval: 300, //5分钟随机时间
              callback: (res: ResponseData) => {
                this.tourList = res.data;
              },
            });
          }
          this.InterfaceCacheModule.getData({
            cacheKey: "coupon.getSimpleCoupons",
            apiMethod: this.$api.goodApi.coupon.getSimpleCoupons,
            params: [productId],
            expireInterval: 28800, //缓存时间 480 分钟
            randInterval: 300, //5分钟随机时间
            callback: (res: ResponseData) => {
              this.couponList = res.data;
            },
          });

          this.InterfaceCacheModule.getData({
            cacheKey: "advert.cityAdverts",
            apiMethod: this.$api.cmsApi.advert.cityAdverts,
            params: ["APP_PROJECT_ADVERT"],
            expireInterval: 28800, //缓存时间 480 分钟
            randInterval: 300, //5分钟随机时间
            callback: ({ data }) => {
              this.advertList = data;
            },
          });
          this.getProductFlashSale();
        }
        this.getIsCollect();
      },
    });
  }
  getData(): string {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return year + "-" + month + "-" + day;
  }
  /**
   * 限时抢购项目获取项目信息
   *  */
  getActivityInfo(): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.InterfaceCacheModule.getData({
      cacheKey: "product.getFlashesByProductIdByActivityId",
      apiMethod: this.$api.goodApi.product.getFlashesByProductIdByActivityId,
      params: [this.activityId, this.productId],
      expireInterval: 28800, //缓存时间 480 分钟
      randInterval: 300, //5分钟随机时间
      callback: ({ data }: { data: good.FlashSaleDto }) => {
        this.activityState = data.activityState!;
        this.flashShowInfo = data;
        this.flashShowInfoDetailList = data.activityShows!;
        if (!this.flashShowInfo.activityShows) {
          this.flashShowInfo.activityShows = [];
        } else {
          this.flashShowInfoDetailList = this.flashShowInfo.activityShows;
          if (this.flashShowInfoDetailList.length === 1) {
            this.showTimes = this.flashShowInfoDetailList[0].saleBeginTimeStr!;
          }
          if (this.showInfoDetailList[0]) {
            this.noticeShowList = this.flashShowInfoDetailList[0].showNotices!;
          }
        }
        Toast.clear();
      },
    });
  }

  getShows(): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.$api.goodApi.show.getShowInfos(
      this.productId,
      {
        distributionChannelId: this.distributionChannelId,
        distributionSeriesId: this.distributionSeriesId,
      },
      ({ data }) => {
        this.showInfo = data;
        if (!this.activityId) {
          if (!this.showInfo.showInfoDetailList) {
            this.showInfo.showInfoDetailList = [];
          } else {
            this.showInfoDetailList = this.showInfo.showInfoDetailList;
            if (this.showInfoDetailList.length === 1) {
              this.showTimes = this.showInfoDetailList[0].saleBeginTimeStr!;
            }
            if (this.showInfoDetailList[0]) {
              this.noticeShowList = this.showInfoDetailList[0].showNotices!;
            }
          }
        }
        Toast.clear();
      }
    );
  }

  // 查询该项目是否已被收藏
  getIsCollect(): void {
    this.$api.memberApi.attentionAndCollection.collectProductAndSellingReminds(
      String(this.productId),
      (data) => {
        this.isCollect = data.data.collect!;
      }
    );
  }

  // 收藏/取消收藏
  collectPro(): void {
    if (!this.UserModule.isLogin && this.AppSource == "harmonyOS") {
      this.jsBridge("needLogin", "", () => {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      });
      return;
    }
    this.$api.memberApi.attentionAndCollection.add(
      String(this.productId),
      (data) => {
        if (data.data) {
          if (this.isCollect) {
            this.isCollect = false;
            this.$toast("取消收藏");
          } else {
            if (!this.tourId) {
              // 非巡演详情页
              monitorEvent(
                "ItemDetail_Fav",
                "收藏",
                this.projectDetail.productName || ""
              ); // 埋点：演出详情页，收藏
            }
            this.isCollect = true;
            this.$toast("收藏成功");
          }
        }
      }
    );
  }

  getProductFlashSale(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "product.getProductFlashSale",
      apiMethod: this.$api.goodApi.product.getProductFlashSale,
      params: [this.productId],
      expireInterval: 28800, //缓存时间 480 分钟
      randInterval: 300, //5分钟随机时间
      callback: ({ data }: { data: Array<good.FlashSaleDto> }) => {
        if (this.activityId) {
          data.forEach((e) => {
            if (e.activityId == this.activityId) {
              this.activityList.push(e);
            }
          });
        } else {
          this.activityList = data;
        }
      },
    });
  }
  openTicket(value: string): void {
    if (this.getIsWeiBo()) {
      return;
    }
    if (!this.UserModule.isLogin && this.AppSource == "harmonyOS") {
      this.jsBridge("needLogin", "", () => {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      });
      return;
    }
    if (
      this.UserModule.isLogin &&
      this.projectDetail.productSource !== "POLY" &&
      this.AppSource == "harmonyOS"
    ) {
      this.$toast("该渠道不支持购买！");
      return;
    }
    // 增加预约状态，点击想看
    if (this.projectDetail.isSellOut === "4") {
      if (this.UserModule.isLogin) {
        this.$api.goodApi.show.getProductCollectRecordByProductIdByProductReservation(
          Number(this.productId),
          !this.productReservation,
          ({ data }) => {
            if (data) {
              this.productReservation = !this.productReservation;
            }
          }
        );
      } else {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      }
      return;
    }
    this.siteName = value;
    if (this.showInfo.source === "POLY") {
      if (
        this.showInfo.productStatus !== "05" &&
        this.showInfo.productStatus !== "12"
      ) {
        this.$toast("该场演出未在售卖中");
      } else {
        this.showTicket = true;
      }
    } else {
      /* 三方项目预售状态可以购票 */
      if (
        this.showInfo.productStatus !== "05" &&
        this.showInfo.productStatus !== "12" &&
        this.showInfo.productStatus !== "10"
      ) {
        this.$toast("该场演出未在售卖中");
      } else {
        this.showTicket = true;
      }
    }
  }

  goback(): void {
    if (this.showScrollTop === "-1") {
      this.$router.go(-1);
    } else {
      this.$router.replace({
        path: "/show/" + this.val,
        query: {
          scrollTop: String(this.showScrollTop),
        },
      });
    }
  }

  openSelectTicket(): void {
    let selectTicket = this.$refs["select-ticket"] as SelectTicket;
    if (selectTicket) {
      selectTicket.initData();
    }
  }
  //丛限时抢购页去项目详情页
  gotoDetail(): void {
    this.$router.push(`/projectdetail/${this.productId}/${this.tourId}`);
  }
}
