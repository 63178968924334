
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Col, Row, Icon, Image, Tag, Popup } from "vant";
import ActivityPopup from "./ActivityPopup.vue";
@Component({
  components: {
    Mixin,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [Popup.name]: Popup,
    ActivityPopup,
  },
})
export default class FlashActivity extends Mixins(Mixin) {
  @Prop({ type: String, default: "" }) activityId!: string;
  @Prop({ type: Number, default: 0 }) public productId!: number;
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  activityList!: Array<good.FlashSaleDto>;

  public showPop = false;
  showActivity(): void {
    this.$emit("showActivity");
  }

  refresh(index: number) {
    this.$emit("refresh", index);
  }
}
