var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tourList.length > 0)?_c('div',{staticClass:"TourStation"},[_c('div',{staticClass:"project-tours"},[_c('van-row',{staticClass:"tours-container"},[_c('van-col',{staticClass:"t-head",on:{"click":_vm.clickAllTour}},[_c('p',{staticClass:"title"},[_vm._v("巡演")]),_c('p',{staticClass:"seemore"},[_vm._v("查看"),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('van-col',{staticClass:"tours-list"},[_c('van-swipe',{attrs:{"loop":false,"width":200,"show-indicators":false}},_vm._l((_vm.tourList),function(item,index){return _c('van-swipe-item',{key:index,staticClass:"tours-item",class:{
              active: item.productId === String(_vm.productId),
              disabled:
                item &&
                item.projectStatus !== '05' &&
                item.projectStatus !== '12',
            },on:{"click":function($event){return _vm.goTourDetail(item)}}},[_c('span',{staticClass:"subTile"},[(item.productId === String(_vm.productId))?_c('img',{staticStyle:{"width":"0.21rem","margin-right":"0.08rem"},attrs:{"src":"https://cdn.polyt.cn/assets/mini_app/performDetail/address-active.png"}}):_c('img',{staticStyle:{"width":"0.17rem","margin-right":"0.08rem"},attrs:{"src":"https://cdn.polyt.cn/assets/mini_app/performDetail/address.png"}}),_vm._v(_vm._s(item.subTile)+" ")]),_c('p',{staticClass:"time"},[_vm._v(" "+_vm._s(item.projectStatus === "05" ? item.productShowTime : _vm.tourLabel(String(item.projectStatus)))+" ")])])}),1)],1)],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }