
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import MySwiper from "@/components/MySwiper.vue";
import { Row, Col, Image, Icon, Swipe, SwipeItem } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    MySwiper,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
})
export default class TourStation extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public tourId!: string;
  @Prop({ default: -1, type: Number }) public productId!: number;
  @Prop({
    default: () => {
      return [];
    },
  })
  public tourList!: good.TourProductDto[];
  public type = "slide";
  public activeIndex = -1;
  public toursList: Array<good.TourProductDto> = [];

  goTourDetail(item: good.TourProductDto): void {
    if (item.projectStatus === "05" || item.projectStatus === "12") {
      monitorEvent("TourPage_ClickItem", "点击一个项目", item.tourTitle); // 埋点：巡演详情页，点击一个项目
      this.activeIndex = this.tourList.indexOf(item) + 1;
      if (item.productId !== String(this.productId)) {
        this.goToProjectDetail(item.productId, this.tourId);
      }
    }
  }

  clickAllTour(): void {
    monitorEvent("TourPage_ClickViewAll", "点击查看全部巡演"); // 埋点：巡演详情页，点击查看全部巡演
    this.$router.push(`/tour/${this.tourId}`);
  }

  getToursList(): void {
    this.activeIndex =
      this.tourList.findIndex(
        (item) => Number(item.productId) === this.productId
      ) + 1;
  }

  tourLabel(projectStatus: string): string {
    let label = "";
    switch (projectStatus) {
      case "01":
        label = "敬请期待";
        break;
      case "12":
        label = "即将开票";
        break;
      case "04":
        label = "已结束";
        break;
      case "06":
        label = "暂停售票";
        break;
      case "08":
        label = "停止售票";
        break;
    }
    return label;
  }
}
