
import { Component, Mixins, Prop, Watch, Vue } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import MyCalendar from "@/views/ProjectDetail/components/Calender/MyCalendar.vue";
import { PricesInformations } from "@/shims-axios";
import {
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Toast,
  Popup,
  Stepper,
  Dialog,
  Calendar,
  CountDown,
} from "vant";
import { SeatInformations } from "@/shims-video";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    MyCalendar,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Stepper.name]: Stepper,
    [Dialog.Component.name]: Dialog.Component,
    [Calendar.name]: Calendar,
    [CountDown.name]: CountDown,
  },
})
export default class SelectTicket extends Mixins(Mixin) {
  @Prop({ default: {} }) public projectDetail!: good.ProjectDto;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  public isCalendar!: string;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public showInfoList!: Array<good.ShowInfoDetailDto>;
  @Prop({ default: "" }) public siteStatus!: string;
  @Prop({ default: "" }) public siteName!: string;
  @Prop({ default: "" }) public productName!: string;
  @Prop({ default: "" }) public productId!: number;
  @Prop({ default: "" }) public projectImg!: string;
  @Prop({ default: "", type: String }) public activityId!: string; // 增加activityId
  @Prop({ default: "", type: String }) public tourId!: string;
  @Prop({ default: "", type: String }) public produceSource!: string; //项目来源
  @Prop({ default: "" }) public showDateArr!: Array<string>; // 演出时间集合
  // @Prop({ default: "" }) public showInfo!: Array<string>; // 项目信息

  public allShowInfo: Array<good.ShowInfoDetailDto> = [];
  public currentShowInfo: good.ShowInfoDetailDto | null = null; // 当前场次信息 null表示未选择
  public currentShowPrice: Array<PricesInformations> = []; //选中的当前场次的票档
  public discountDescribe: any = []; //优惠折扣文案
  public ticketsPriceList: Array<PricesInformations> = []; //选择票档
  public currentPriceList: Array<PricesInformations> = []; //选择票数
  public showsList: Array<string> = [];
  public timesList: Array<string> = [];
  public showDateList: Array<string> = []; //格式化演出日期
  public showLimited = false;
  public value = 3;
  public seatInfo: Array<SeatInformations> = [];
  public isNoStock = false;
  public priceId = -1;
  public purchasableCount = 0; //当前场次用户可以购买的票数
  public calendarAllShowList: Array<good.ShowInfoDetailDto> = []; // 选中日期场次集合
  public calendarShowBtn = ""; //日历展示的场次按钮
  public calendarShowItem: good.ShowInfoDetailDto | null = null; // 日历选中的项目
  public choseIndex = -1;
  public maxBuyLimitPerOrder = 0; // 猫眼项目单笔最大购买数量
  public saleReminder = {
    // 即将开始状态下的各种值
    isRemind: false, // 用户是否开启开票提醒
    countdownTime: 0, // 即将开始倒计时
    saleBeginTime: "",
  };
  public isClick = false;

  @Watch("showInfoList")
  changeShowList(val: Array<good.ShowInfoDetailDto>): void {
    this.allShowInfo = val;
    if (this.allShowInfo.length === 1) {
      this.handleSelect(this.allShowInfo[0]);
    } else {
      this.handleSelect({});
    }
  }
  // 场次按钮展示文案
  getShowsName(
    showNameLabel: string | undefined,
    showTime: string | undefined,
    showStatusName: string | undefined,
    status: string | undefined
  ): { name: string; label: string } {
    let obj = {
      name: "",
      label: "",
    };
    obj.name = showNameLabel ? showNameLabel : showTime!;
    if (status !== "12" && status !== "05") {
      obj.label = `${showStatusName}`;
    }

    return obj;
  }
  /**
   * 初始化
   */
  initData(): void {
    this.allShowInfo = this.showInfoList;
    if (this.allShowInfo.length === 1) {
      this.handleSelect(this.allShowInfo[0]);
    }
    this.showDateArr.forEach((item) => {
      let date = this.timestampFormat(item ? Number(item) : 0, "YYYY/MM/DD");
      this.showDateList.push(date);
    });

    let myCalendar = this.$refs["my-calendar"] as MyCalendar;
    if (myCalendar) {
      myCalendar.initData();
    }
  }
  // 检查是否开启开票提醒
  checkReminder(): void {
    if (this.produceSource !== "POLY") {
      return;
    }
    if (this.currentShowInfo?.status !== "12") {
      return;
    }
    this.saleReminder.saleBeginTime = this.currentShowInfo.saleBeginTime || "";
    this.$api.memberApi.attentionAndCollection.getMemberShow(
      this.productId,
      this.currentShowInfo.saleBeginTime
        ? +this.currentShowInfo.saleBeginTime
        : 0,
      this.currentShowInfo.showId || 0,
      ({ data }) => {
        this.saleReminder.isRemind = data.isRemind === "1";
        // const now = new Date().getTime();
        const countdownTime =
          +this.saleReminder.saleBeginTime - data.timestamp!;
        // 时间在一小时以内使用倒计时模块
        if (countdownTime <= 60 * 60 * 1000) {
          this.saleReminder.countdownTime = countdownTime;
        } else {
          this.saleReminder.countdownTime = 0;
        }
      }
    );
  }
  // 缺货登记
  saleOutRegist(): void {
    this.$api.memberApi.record.userLackTicketRecord(
      this.priceId,
      Number(this.productId),
      Number(this.currentShowInfo!.showId),
      {},
      ({ data }) => {
        this.$toast(data);
      }
    );
  }
  // 开启开票提醒
  openReminder(): void {
    if (this.projectDetail.isOpenticket === "0") {
      return;
    }
    const currentShowInfo: any = this.currentShowInfo;
    this.$api.memberApi.attentionAndCollection.editShowRemind(
      this.saleReminder.isRemind ? "0" : "1",
      currentShowInfo.showId || 0,
      ({ data }) => {
        if (data) {
          this.saleReminder.isRemind = !this.saleReminder.isRemind;
          if (this.saleReminder.isRemind) {
            if (Vue.prototype.$AppSource == "harmonyOS") {
              let params = {
                productName: this.projectDetail.productName,
                placeName: this.projectDetail.showPlaceName,
                showStartTimestamp: currentShowInfo.saleBeginTime,
              };
              this.jsBridge("addCalendar", params);
              this.$toast("添加日历提醒成功，开抢前15分钟将收到开票提醒推送");
            } else {
              this.$toast("已开启提醒");
            }
          } else {
            this.$toast("您已取消提醒");
          }
        }
      }
    );
  }
  // 倒计时结束
  countDownFinish(): void {
    console.log(this.currentShowInfo);
    if (this.currentShowInfo) {
      this.currentShowInfo.status = "05";
    }
  }

  // 选择场次
  handleSelect(item: good.ShowInfoDetailDto | null): void {
    if (!item || (item.status !== "05" && item.status !== "12")) {
      return;
    }
    this.choseIndex = this.calendarAllShowList.indexOf(item!) + 1;
    this.calendarShowItem = item;
    this.currentShowInfo = item;
    this.checkReminder();
    this.discountDescribe = [];
    if (item.discountDescribeList && item.discountDescribeList.length) {
      item.discountDescribeList!.map((desItem: any) => {
        this.discountDescribe.push(desItem.discountDescribe);
      });
    }
    if (this.produceSource !== "MY") {
      this.currentShowPrice! = item ? item.ticketPriceList! || [] : [];
    } else {
      let priceListA = item!.ticketPriceList!;
      let priceListB =
        item!.thirdShowSetting!.maoYanShowSettingDto!.ticketSettingDtos!;
      this.maxBuyLimitPerOrder =
        item!.thirdShowSetting!.maoYanShowSettingDto!.maxBuyLimitPerOrder!;
      for (let i = 0; i < priceListA.length; i++) {
        for (let j = 0; j < priceListB.length; j++) {
          if (priceListA[i].priceId === priceListB[j].priceId) {
            this.$set(priceListA[i], "sellStatus", priceListB[j].sellStatus);
            this.$set(priceListA[i], "minBuyLimit", priceListB[j].minBuyLimit);
            this.$set(priceListA[i], "maxBuyLimit", priceListB[j].maxBuyLimit);
            this.$set(
              priceListA[i],
              "currentAmount",
              priceListB[j].currentAmount
            );
          }
        }
      }
      this.currentShowPrice = item!.ticketPriceList!;
    }
    this.currentPriceList = [];
    this.ticketsPriceList = [];
    if (item) {
      this.purchasableCount = item.userAvailableCount!;
    }
  }

  chooseDay(day: Date): void {
    this.choseIndex = -1;
    this.currentShowInfo = {};
    this.currentShowPrice = [];
    this.calendarAllShowList = [];
    this.currentPriceList = [];
    this.ticketsPriceList = [];
    let date = this.timestampFormat(day.getTime());
    let arr = date.split(" ");
    this.allShowInfo.forEach((item) => {
      let arr2 = item.showTime!.split(" ");
      if (arr2[0] === arr[0] && item.showId) {
        this.calendarAllShowList.push(item);
      }
    });
    if (this.calendarAllShowList.length > 1) {
      this.handleSelect({});
    } else {
      this.handleSelect(this.calendarAllShowList[0]);
    }
  }
  // 选择票档
  selectTicket(item: PricesInformations): void {
    this.priceId = item.priceId || -1;
    this.isNoStock = item.reservedCount! > 0 ? false : true; // 该票档是否缺货
    let currentShowInfo = this.currentShowInfo;
    if (!currentShowInfo) {
      this.$toast("请先选择场次");
      return;
    }
    if (currentShowInfo.site) {
      // 选座
      if (this.ticketsPriceList.indexOf(item) !== -1) {
        // 若该票档为缺货的票档，选择缺货票档底部按钮要置灰。反选后为高亮状态
        if (this.isNoStock) {
          this.isNoStock = !this.isNoStock;
        }
        this.ticketsPriceList = [];
      } else {
        if (this.ticketsPriceList.length === 0) {
          this.ticketsPriceList.push(item);
        } else {
          this.ticketsPriceList = [];
          this.ticketsPriceList.push(item);
        }
      }
    } else {
      //
      /**
       * 立即购票
       * 场次限购字段：
       * 若场次不限购将返回-1（this.currentShowInfo.showRestriction === 1），当场次不限购时，不需要判断用户可购买票数，用户可随意购买。
       * 场次限购不为-1时（this.currentShowInfo.showRestriction !== 1），需判断用户可购买票数
       * **/
      if (this.ticketsPriceList.indexOf(item) !== -1) {
        // 该票档已经被选择，取消选中。重新计算
        if (this.isNoStock) {
          this.isNoStock = !this.isNoStock;
        }
        this.ticketsPriceList.splice(this.ticketsPriceList.indexOf(item), 1);
        let index = this.currentPriceList.findIndex((current) => {
          return (
            item.priceId === current.priceId &&
            item.priceWay === current.priceWay
          );
        });

        this.currentPriceList.splice(index, 1);
      } else {
        // 该票档没有被选择
        // 如果已经选择了无票票档，或者选择无票票档，需要清空已选择的票档
        if (
          this.produceSource !== "POLY" ||
          this.hasDifferentTicket(this.ticketsPriceList, item)
        ) {
          this.ticketsPriceList = [];
          this.currentPriceList = [];
        }
        if (this.calculateRestriction(1, item, NaN)) {
          this.$set(item, "count", 1);
          this.ticketsPriceList.push(item);
          this.currentPriceList.push(item);
          this.calculateRestriction(1, item, NaN);
        }
        if (!this.calculateRestriction(1, item, NaN)) {
          this.ticketsPriceList.splice(this.ticketsPriceList.indexOf(item), 1);
          this.currentPriceList.splice(this.ticketsPriceList.indexOf(item), 1);
        }
      }
    }
  }
  // 判断 已选票档 不能有无票和有票两种
  hasDifferentTicket(priceList: any, item: any): boolean {
    let index = priceList.findIndex(
      (current: { priceId: any; reservedCount: any }) => {
        return Boolean(item.reservedCount) != Boolean(current.reservedCount);
      }
    );
    return index !== -1;
  }
  changeCount(count: number | undefined, index: number, type: string) {
    if (count === undefined) {
      return;
    }
    if (type === "add") {
      this.changeVal(count + 1, index);
    } else {
      if (count && count > 1) {
        this.changeVal(count - 1, index);
      }
    }
  }
  changeVal(value: number, index: number): void {
    const item = this.currentPriceList[index];
    if (this.calculateRestriction(value, item, index)) {
      item.count = value;
    }
  }

  calculateRestriction(
    value: number,
    item: PricesInformations,
    index: number
  ): boolean {
    let currentShowInfo = this.currentShowInfo;
    if (!currentShowInfo) {
      this.$toast("请先选择场次");
      return false;
    }
    const count = value * item.ticketCount!; // 当前选中票档的总票数
    let allCount = 0; // 所有已选中的票档的票数
    this.currentPriceList.forEach((currentValue, currentIndex) => {
      if (index === currentIndex) {
        allCount += count;
      } else {
        allCount += currentValue.count! * currentValue.ticketCount!;
      }
    });
    // if (Number.isNaN(index)) {
    //   allCount += count;
    // }
    /**
     * 场次限购的情况下，判断用户可购买数量
     * item.reservedCount 当前票档的库存
     * this.purchasableCount当前场次用户可购买数量
     * this.currentShowInfo.showRestriction 当前场次限购数量
     * this.maxBuyLimitPerOrder 猫眼单笔订单最大购买票数
     * **/
    /* 保利项目限购规则 */
    if (
      currentShowInfo.showRestriction !== -1 &&
      this.produceSource == "POLY"
    ) {
      if (count > item.reservedCount! && this.produceSource === "POLY") {
        this.ticketsPriceList = [];
        this.ticketsPriceList.push(item);
        // this.$toast("余票不足，看看其他价格");
        return false;
      }
      if (count > this.purchasableCount) {
        this.$toast(`单场次限购${currentShowInfo.showRestriction}张票`);
        return false;
      }
      if (allCount > this.purchasableCount) {
        this.$toast(`单场次限购${currentShowInfo.showRestriction}张票`);
        return false;
      }
    }
    /* 猫眼限购规则：场次限购；不考虑票档限购 */
    // if (this.produceSource === "MY") {
    // if (count > item.currentAmount!) {
    //   this.$toast("余票不足，看看其他价格");
    //   return false;
    // }
    // if (count > item.maxBuyLimit!) {
    //   this.$toast(`该票档限购${item.maxBuyLimit}张票`);
    //   return false;
    // }
    // if (allCount > this.maxBuyLimitPerOrder!) {
    //   this.$toast(`单场次限购${this.maxBuyLimitPerOrder}张票`);
    //   return false;
    // }
    // if (count < item.minBuyLimit!) {
    //   this.$toast(`该票档至少购买${item.minBuyLimit}张票`);
    //   return false;
    // }
    // }
    /* 麻花限购规则：单笔订单限购6张 */
    // if (this.produceSource === "MH") {
    //   if (count > 6) {
    //     this.$toast(`单笔订单限购6张票`);
    //     return false;
    //   }
    // }
    /* 大麦限购规则：前端不做判断 */
    return true;
  }

  /**
   * 点击【立即购票】或【选座购买】
   */
  buyTicket(): void {
    let currentShowInfo = this.currentShowInfo;
    if (!currentShowInfo) {
      this.$toast("请选择场次");
      return;
    }
    if (this.isNoStock) {
      return;
    }
    let showId = Number(currentShowInfo.showId);
    if (currentShowInfo.site) {
      // 选座购买
      if (this.tourId) {
        // 巡演详情页
        monitorEvent("TourDetail_ClickChooseSeat", "点击选座购票"); // 埋点：巡演详情页，点击选座购票
      } else {
        // 不是巡演详情页
        monitorEvent(
          "ItemDetail_ClickChooseSeat",
          "点击选座购买",
          this.productName
        ); // 埋点：演出详情页，点击选座购买
      }
      this.isMemberFirstBuy(showId, () => {
        this.buyTicketForSelectSeat(currentShowInfo!);
      });
    } else {
      // 立即购买
      this.isMemberFirstBuy(showId, () => {
        this.buyTicketImmediately(showId);
      });
    }
  }

  isMemberFirstBuy(showId: number, callback: () => void): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.$api.goodApi.show.checkPriorityPurchase(showId, ({ data }) => {
      if (data) {
        Toast.clear();
        callback();
      }
    });
  }

  /**
   * 点击【选座购买】
   */
  buyTicketForSelectSeat(currentShowInfo: good.ShowInfoDetailDto): void {
    let showId = Number(currentShowInfo.showId);
    if (this.projectDetail.productSource === "DM") {
      // 大麦选座
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      this.$api.goodApi.daMai.getSeatTokent(
        this.productId,
        showId,
        ({ data }) => {
          Toast.clear();
          window.location.href = data.seatUrl || "";
        }
      );
      return;
    }
    if (currentShowInfo.sectionNum! > 1) {
      // 选择区域
      this.$router.push({
        path: `/select/${showId}/${this.productId}?productSource=${
          this.projectDetail.productSource || ""
        }`,
      });
    } else {
      // 这种情况需要获取一下 CDN 的 JSONP 信息，暂时调用获取分区 svg 的接口来获取
      this.SelectSectionModule.SET_sectionIdWebCdnPathMap_PERSIST({});
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      this.$api.goodApi.show.getSectionInfo(
        showId,
        {
          distributionChannelId: this.distributionChannelId,
          distributionSeriesId: this.distributionSeriesId,
        },
        ({ data }) => {
          let showSectionDtos = data.showSectionDtos;
          let sectionId = "";
          if (showSectionDtos && showSectionDtos.length > 0) {
            let sectionIdWebCdnPathMap: Record<string, string> = {};
            sectionId = showSectionDtos[0].sectionId
              ? String(showSectionDtos[0].sectionId)
              : "";
            let webCdnPath = showSectionDtos[0].webCdnPath;
            if (sectionId && webCdnPath) {
              sectionIdWebCdnPathMap[sectionId] = webCdnPath;
            }
            this.SelectSectionModule.SET_sectionIdWebCdnPathMap_PERSIST(
              sectionIdWebCdnPathMap
            );
          }
          // 选座购买
          this.$router.push({
            path: `/select-seat?showId=${currentShowInfo.showId}&productId=${this.productId}&sectionId=${sectionId}&priceId=${this.priceId}`,
          });
          Toast.clear();
        }
      );
    }
  }

  /**
   * 点击【立即购票】
   */
  buyTicketImmediately(showId: number): void {
    if (!this.currentPriceList.length) {
      this.$toast("请选择票档");
    } else {
      if (!this.tourId) {
        // 不是巡演详情页
        monitorEvent("ItemDetail_ClickBuy", "点击立即购买", this.productName); // 埋点：演出详情页，点击立即购买
      }
      // 接口入参
      let priceList: Array<Record<string, unknown>> = [];
      this.currentPriceList.forEach((item) => {
        priceList.push({
          num: item.count! * item.ticketCount!,
          priceId: item.priceId!,
          saleclassId: item.saleclassId!,
        });
      });
      if (this.AppSource == "harmonyOS") {
        //立即锁座接口改为三方立即锁座，填加productSourceEnum字段，来源
        if (!this.isClick) {
          this.isClick = true;
          window.setTimeout(() => {
            this.$api.orderApi.ticketOrder.lockSeatNow(
              {
                priceList: priceList,
                showId,
                activityId: this.activityId, //增加activityId
                distributionId: this.distributionChannelId,
                seriesId: this.distributionSeriesId,
                productId:
                  this.projectDetail.productSource === "DM"
                    ? String(this.productId)
                    : "",
                salesType: this.projectDetail.salesType,
              },
              ({ data }) => {
                let params = {
                  theaterId: this.TheaterModule.theaterId,
                  uuid: data,
                };
                this.jsBridge("goConfirmOrder", params);
              }
            );
            this.isClick = false;
          }, 2000);
        }
      } else {
        //立即锁座接口改为三方立即锁座，填加productSourceEnum字段，来源
        this.$api.orderApi.ticketOrder.lockSeatNow(
          {
            priceList: priceList,
            showId,
            activityId: this.activityId, //增加activityId
            distributionId: this.distributionChannelId,
            seriesId: this.distributionSeriesId,
            productId:
              this.projectDetail.productSource === "DM"
                ? String(this.productId)
                : "",
            salesType: this.projectDetail.salesType,
          },
          ({ data }) => {
            this.goToOrderConfirm(data);
          }
        );
      }
    }
  }
}
