var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"SelectTicket"},[_c('div',{staticClass:"header"},[(_vm.siteStatus === '12')?_c('div',[_vm._v("即将开票")]):[(_vm.currentShowInfo && _vm.currentShowInfo.status === '05')?_c('div',[_vm._v(" "+_vm._s(_vm.currentShowInfo.site ? "选座购买" : "立即购买")+" ")]):(_vm.currentShowInfo && _vm.currentShowInfo.status === '12')?_c('div',[_vm._v(" 即将开票 ")]):_c('div',[_vm._v(" "+_vm._s(_vm.siteName)+" ")])]],2),_c('div',{staticClass:"safe-area"},[(_vm.isCalendar === '1')?_c('van-row',{staticClass:"show-ticket-item"},[_c('h3',{staticClass:"show-ticket-title"},[_vm._v("选择日期")]),_c('MyCalendar',{ref:"my-calendar",staticClass:"calender-wrapper",attrs:{"is-calendar":_vm.isCalendar,"show-date-arr":_vm.showDateArr,"show-date-list":_vm.showDateList},on:{"choose":_vm.chooseDay}})],1):_vm._e(),(_vm.showInfoList.length)?_c('van-row',{staticClass:"show-ticket-item"},[_c('h3',{staticClass:"show-ticket-title"},[_vm._v("选择场次")]),(_vm.isCalendar === '1')?_c('van-col',{staticClass:"show-ticket-tag show"},_vm._l((_vm.calendarAllShowList),function(item,index){return _c('button',{key:index,staticClass:"show-ticket-time",class:{
            active: _vm.choseIndex && _vm.choseIndex === index + 1,
            disabled: item && item.status !== '05' && item.status !== '12',
          },attrs:{"disabled":item && item.status !== '05' && item.status !== '12'},on:{"click":function($event){return _vm.handleSelect(item)}}},[_c('p',{staticClass:"shows-name"},[_vm._v(" "+_vm._s(_vm.getShowsName( item.showNameLabel, item.showTime, item.showStatusName, item.status ).name)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.status !== '05' && item.status !== '12'),expression:"item.status !== '05' && item.status !== '12'"}],staticClass:"show-status"},[_vm._v(" "+_vm._s(_vm.getShowsName( item.showNameLabel, item.showTime, item.showStatusName, item.status ).label)+" ")])])}),0):_c('van-col',{staticClass:"show-ticket-tag show"},_vm._l((_vm.allShowInfo),function(item,index){return _c('button',{key:`show-${index}`,staticClass:"show-ticket-time",class:{
            active: _vm.currentShowInfo && item.showId == _vm.currentShowInfo.showId,
            disabled: item && item.status !== '05' && item.status !== '12',
          },attrs:{"disabled":item && item.status !== '05' && item.status !== '12'},on:{"click":function($event){return _vm.handleSelect(item)}}},[_vm._v(" "+_vm._s(_vm.getShowsName( item.showNameLabel, item.showTime, item.showStatusName, item.status ).name)+" "),_c('p',{staticStyle:{"font-weight":"400","font-size":"0.24rem","color":"#666666"}},[_vm._v(" "+_vm._s(_vm.getShowsName( item.showNameLabel, item.showTime, item.showStatusName, item.status ).label)+" ")])])}),0)],1):_vm._e(),(
        _vm.currentShowInfo &&
        _vm.currentShowPrice.length &&
        !(_vm.currentShowInfo.status === '06' || _vm.currentShowInfo.status === '08')
      )?_c('van-row',{staticClass:"show-ticket-item"},[_c('h3',{staticClass:"show-ticket-title"},[_vm._v(" 选择票档 "),(_vm.currentShowInfo.site === 0)?_c('span',{staticStyle:{"font-weight":"400","font-size":"0.24rem","color":"#999999","margin-top":"0.2rem"}},[_vm._v(" 根据票面随机分配座位 ")]):_vm._e()]),_c('van-col',{staticClass:"show-ticket-tag ticket"},_vm._l((_vm.currentShowPrice),function(item,index){return _c('button',{key:`ticket-${index}`,staticClass:"show-ticket-price",class:{
            checked: _vm.ticketsPriceList.includes(item),
            disabled:
              _vm.currentShowInfo.status === '12' ||
              item.sellStatus === 1 ||
              item.sellStatus === 5 ||
              item.sellStatus === 12,
          },attrs:{"disabled":_vm.currentShowInfo.status === '12' ||
            item.sellStatus === 1 ||
            item.sellStatus === 5 ||
            item.sellStatus === 12},on:{"click":function($event){return _vm.selectTicket(item)}}},[_vm._v(" "+_vm._s(item.price)+" "+_vm._s(item.priceWay)+" "),_c('div',{staticClass:"sign"},[_vm._v(_vm._s(item.sign))]),(item.reservedCount === 0)?_c('van-image',{staticClass:"no-stock-img",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Project/noTicket.png')}}):_vm._e()],1)}),0)],1):_vm._e(),(_vm.currentShowInfo && _vm.currentPriceList.length)?_c('van-row',{staticClass:"show-ticket-item"},[_c('h3',{staticClass:"show-ticket-title"},[_vm._v("选择数量")]),_c('van-col',{staticClass:"show-ticket-tag number"},_vm._l((_vm.currentPriceList),function(item,index){return _c('div',{key:`number-${index}`,staticClass:"show-ticket-num"},[_c('span',[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"Stepper"},[_c('van-icon',{staticClass:"iconfont icon-minus",class:{ disabled: item.count && item.count <= 1 },on:{"click":function($event){return _vm.changeCount(item.count, index, 'minus')}}}),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(item.count))]),_c('van-icon',{staticClass:"iconfont icon-add",on:{"click":function($event){return _vm.changeCount(item.count, index, 'add')}}})],1)])}),0)],1):_vm._e()],1),_c('div',{staticClass:"bottom-area"},[(
        (_vm.produceSource === 'POLY' &&
          _vm.currentShowInfo &&
          _vm.currentShowInfo.status === '12') ||
        (_vm.projectDetail.thirdProductStatus == 'WILL_SALE' &&
          _vm.currentShowInfo &&
          _vm.currentShowInfo.saleBeginTime &&
          new Date(_vm.currentShowInfo.saleBeginTime).getTime() <
            new Date().getTime())
      )?[_c('div',{staticClass:"begin-time"},[_c('span',{staticClass:"begin-time-timer"},[(_vm.saleReminder.countdownTime)?_c('van-count-down',{attrs:{"time":_vm.saleReminder.countdownTime},on:{"finish":_vm.countDownFinish},scopedSlots:_vm._u([{key:"default",fn:function(timeData){return [_c('span',{staticClass:"block"},[_vm._v(_vm._s(("00" + timeData.minutes).slice(-2)))]),_c('span',{staticClass:"colon"},[_vm._v("分")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(("00" + timeData.seconds).slice(-2)))]),_c('span',{staticClass:"colon"},[_vm._v("秒")])]}}],null,false,1709828753)}):[_vm._v(" "+_vm._s(_vm.dateFormat( new Date(_vm.saleReminder.saleBeginTime), "YYYY.MM.DD HH:mm" ))+" ")]],2),_c('span',{staticClass:"begin-time-text"},[_vm._v(_vm._s(_vm.saleReminder.countdownTime ? "后" : "")+"开票")])]),_c('div',{staticClass:"btn-wrap"},[(
            _vm.produceSource !== 'POLY' || _vm.projectDetail.isOpenticket === '0'
          )?_c('van-button',{staticClass:"buy-btn disabled",attrs:{"size":"large"}},[_vm._v("即将开票")]):_c('van-button',{staticClass:"buy-btn",attrs:{"size":"large"},on:{"click":_vm.openReminder}},[_vm._v(" "+_vm._s(_vm.projectDetail.isOpenticket === "1" ? _vm.saleReminder.isRemind ? "已开启提醒" : "开票提醒" : "即将开票")+" ")])],1)]:_c('div',{staticClass:"btn-wrap"},[(_vm.isNoStock && _vm.projectDetail.outStockRegister)?_c('van-button',{staticClass:"buy-btn",attrs:{"size":"large"},on:{"click":_vm.saleOutRegist}},[_c('div',[_vm._v("缺货登记")])]):_c('van-button',{staticClass:"buy-btn",class:{
          disabled:
            _vm.isNoStock || (_vm.currentShowInfo && _vm.currentShowInfo.status !== '05'),
        },attrs:{"size":"large","disabled":_vm.isNoStock || (_vm.currentShowInfo && _vm.currentShowInfo.status !== '05')},on:{"click":_vm.buyTicket}},[(!_vm.currentShowInfo && _vm.siteStatus === '12')?_c('div',[_vm._v("即将开票")]):[(_vm.currentShowInfo && _vm.currentShowInfo.status === '05')?_c('div',[_vm._v(" "+_vm._s(!_vm.currentShowInfo.site ? "立即购买" : "选座购买")+" ")]):(_vm.currentShowInfo && _vm.currentShowInfo.status === '12')?_c('div',[_vm._v(" 即将开票 ")]):_c('div',[_vm._v(" "+_vm._s(_vm.siteName)+" ")])]],2)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }