
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import DetailComp from "./detailComp.vue";

@Component({
  components: { Mixin, DetailComp },
})
export default class ActivityDetail extends Mixins(Mixin) {
  public activityId: string | (string | null)[] = "";
  public tourId: string | (string | null)[] = "";
  public productId = 0;

  mounted(): void {
    this.activityId = this.$route.params.activityId!;
    this.tourId = this.$route.params.tourId!;
    this.productId = Number(this.$route.params.productId!);
    this.$nextTick(() => {
      let detailComp = this.$refs["detail-comp"] as DetailComp;
      if (detailComp) {
        detailComp.refresh();
      }
    });
    if (this.ChannelFromModule.channelFrom === "BESTPAY") {
      this.ChannelFromModule.SET_productId_PERSIST(String(this.productId));
      this.ChannelFromModule.SET_tourId_PERSIST(this.tourId);
    }
  }
}
