
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Icon } from "vant";

@Component({
  components: {
    [Icon.name]: Icon,
  },
})
export default class YbyScore extends Mixins(Mixin) {
  @Prop({
    default: "01",
    type: String,
  })
  type!: string;
  @Prop({
    default: "",
    type: String,
  })
  businessId!: string;
  @Watch("businessId")
  changeId() {
    this.getYbyScore();
  }
  public ybyInfo = {} as cms.productScoreRes;
  getYbyScore() {
    this.$api.cmsApi.ybyContent.getYbyProductScoreRes(
      this.businessId,
      this.type,
      ({ data }) => {
        this.ybyInfo = data;
      }
    );
  }
  gotoDetail() {
    if (this.ybyInfo.ybyContentId) {
      window.location.href =
        location.origin + "/third/#/yuboya/" + this.ybyInfo.ybyContentId;
    }
  }
  goYbyScorce() {
    window.location.href = location.origin + "/third/#/yby-rule/";
  }
}
