
import { Mixin } from "@/core/mixins/mixin";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
@Component({
  components: {
    Mixin,
  },
})
export default class projectPopup extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public eventType!: string;
  @Watch("eventType")
  chanegEventType(value: string): void {
    this.eventType = value;
  }
}
